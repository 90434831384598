import { Dispatch, Fragment, SetStateAction } from "react";
import { ActionableType, Application, CustomSwitchableEvaluatorQualifierField, Customer, Field, FieldType, Organization, User } from "src/types";

import { IncodeDocumentIdInput } from "./IncodeDocumentId";
import { IncodeProofAddressInput } from "./IncodeProofAddress";
import { IncodeProofIncomeInput } from "./IncodeProofIncome";
import { BankStatementInput } from "./BankStatement";
import { TaxIdStatusInput } from "./TaxIdStatus";
import { UploadInput } from "./Upload";
import { ApplicationService } from "src/services";

export interface UploadComponentProps {
    type: string
    onUpload: (attachment?: FormData, application?: any, id?: string) => void

    /** Use to set manually field key, that "key" prop is reserved in react components */
    fieldKey: string

    actionableId: string,
    actionableType: ActionableType

    // special props
    approvalId: string
    user?: User
    customer: Customer,
    approval: CustomSwitchableEvaluatorQualifierField
    organization: Organization
    application: Application
    applicationService: typeof ApplicationService
    isUploading: boolean
    actionable: {
        id?: string,
        type?: ActionableType
    }
    dispatch: Dispatch<any>
    setIsUploading: Dispatch<SetStateAction<boolean>>
    handleClose: () => void
}

//@ts-ignore
const Components: { [key in string]: ComponentType<UploadComponentProps> } = {
    file_upload: UploadInput,
    incode_document_id: IncodeDocumentIdInput,
    incode_proof_of_address: IncodeProofAddressInput,
    incode_proof_of_income: IncodeProofIncomeInput,
    bank_statement: BankStatementInput,
    tax_id_status: TaxIdStatusInput,
}

/** Render an upload component by field type:
 ** file_upload
 ** incode_document_id
 ** incode_proof_of_address
 ** incode_proof_of_income
 ** bank_statement
 ** tax_id_status
 *
 * Is important fetch before the following data:
 ** customer
 ** organization
 ** application
 * @param props 
 * @returns 
 */
export function UploadComponent(props: UploadComponentProps) {
    const Component = Components[props?.type || ''] || Fragment
    return <Component {...props} />
}