const deployEnvironments: { [key: string]: string } = {
    development: 'dev.',
    staging: 'dev.',
    sandbox: 'sandbox.',
    production: ''
}

const cookieNameEnvironments: { [key: string]: string } = {
    development: 'customer-auth-dev',
    staging: 'customer-auth-dev',
    sandbox: 'customer-auth-sbx',
    production: 'customer-auth',
    '': 'customer-auth-dev'
}

export function getCookieDomain(): string {
    return import.meta.env.DEV
        ? window.location.hostname
        : `${deployEnvironments[import.meta.env.REACT_APP_ENV!]}kredi.mx`
}

export function getCookieName(): string {
    return `${cookieNameEnvironments[import.meta.env.REACT_APP_ENV!]}` || 'customer-auth-dev'
}