import { Typography, Grid, Button } from "@mui/material";
import { KeyboardArrowLeft as LeftIcon, KeyboardArrowRight as RightIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsMobile, isValidId, hiddenFields, segment, navigate } from "src/helpers";
import { stageActions } from "src/store/stages/actions";
import { ApplicationWorkflowStage, ApplicationWorkflowSection, SectionSummary, Field } from "src/types";
import { Edit } from "@mui/icons-material";
import { Link, Loading, StageSummaryField } from "src/components";
import { TermsConditions } from "./TermsConditions";
import { usetStyles } from './Summary.styles'
import queryString from 'query-string';
import _ from 'lodash';
import { applicationActions } from "src/store/application/actions";

export interface SummaryProps {
	currentStage?: ApplicationWorkflowStage
	applicationId: string,
	goToSection: (section: ApplicationWorkflowSection) => void
}

export function Summary({ currentStage, applicationId, goToSection }: SummaryProps) {
	const { classes, cx } = usetStyles();
	const dispatch = useDispatch()
	const isMobile = useIsMobile()

	const { application, isPublishing } = useSelector(state => state.applications)
	const { stageSummary, isLoadingSummary } = useSelector(state => state.stages)
	const isFirstStage = application?.previous_stage == null || false
	const [isTermsConditionsAccepted, setIsTermsConditionsAccepted] = useState(false)

	useEffect(() => {
		dispatch(applicationActions.set('summaryAlreadyShown', true))
	}, [])

	useEffect(() => {
		if (isValidId(currentStage?.id) && isValidId(applicationId)) {
			dispatch(stageActions.getStageSummary(applicationId, currentStage?.id))
		}
		return () => {
			dispatch(stageActions.setStageSummary([]))
		}

	}, [currentStage?.id, applicationId])

	function getPreviousSectionLink(): string {
		const { from } = queryString.parse(window.location.search);
		return `/application/${application?.id}/${application?.current_stage?.slug}/${application?.current_section?.slug}${from ? '?from=' + from : ''}`
	}

	const handleTermsConditions = (isAllChecked: boolean) => {
		setIsTermsConditionsAccepted(isAllChecked)
	}

	return (
		<Loading isLoading={isLoadingSummary}>

			<div className={classes.root} style={{ width: '100%' }}>
				<div className={classes.headerContainer}>
					{!isMobile && <Typography variant="h1"> Resumen </Typography>}
					<Typography variant="h6"> Este es un resumen de la información que nos has proporcionado para continuar con tu precalificación. </Typography>
				</div>
				<div className={classes.summaryContainer}>
					<div className={classes.summaryData}>
						{_.sortBy(stageSummary, ["sort_order"]).map((section: SectionSummary, index: number) => {
							return section?.hidden ? null : (
								<Grid key={index} container>
									<Grid item xs={12} className={classes.stageTitle}>
										<div className={classes.titleContainer}>
											<Typography variant="h4"> {section?.title} </Typography>
										</div>
										<div className={classes.chipContainer} onClick={() => goToSection(section)}>
											<p>
												Editar <Edit />
											</p>
										</div>
									</Grid>
									<Grid container spacing={2}>
										{_.sortBy(section?.answers, 'sort_order')
											.filter((answer) => !hiddenFields.includes(answer.field_type!))
											.map((answer) => {
												return (
													<StageSummaryField key={answer.id} field={answer} value={answer.value} />
												)
											})}
									</Grid>
								</Grid>
							)
						})}
					</div>
				</div>

				{(isFirstStage && (
					<div className={cx(classes.summaryContainer, classes.termsConditionsContainer)}>
						<div className={classes.summaryData}>
							<TermsConditions
								titleGridProps={{
									className: classes.stageTitle
								}}
								titleContainerProps={{
									className: classes.titleContainer
								}}
								onCheckToggle={handleTermsConditions}
							/>
						</div>
					</div>
				))}

				<div className={classes.buttonContainer}>
					<Link to={getPreviousSectionLink()} className={classes.cancelButton}>
						<Button
							variant="outlined"
							color="primary"
							startIcon={<LeftIcon />}
							className={cx(classes.button, classes.cancelButton)}
						>
							Volver
						</Button>
					</Link>
					<Button
						variant="contained"
						color="primary"
						endIcon={<RightIcon />}
						className={cx(classes.button, classes.submitButton)}
						onClick={() => {
							segment.track('Application', 'Completed', {
								id: applicationId,
								action: 'Application Completed'
							})
							navigate.to(`/application/${applicationId}/running_publishing`)
						}}
						disabled={(isFirstStage && !isTermsConditionsAccepted) || isPublishing}
					>
						Finalizar
					</Button>
				</div>

			</div>
		</Loading>
	)
}