import { CreditProposalKey, CreditProposalType } from "src/types"

import {
    currencyString,
    formatDate,
    percentage,
    translateCreditProposalSchema,
    translateCreditProposalStatus
} from "src/helpers"

import _ from 'lodash'

export const concepts: {
    key: CreditProposalKey,
    title: string,
    render?: (proposal: CreditProposalType) => string
}[] = [
        {
            key: 'status',
            title: 'Situación',
            render: proposal => translateCreditProposalStatus[proposal?.status || ''],
        },
        {
            key: 'schema',
            title: 'Esquema',
            render: proposal => translateCreditProposalSchema[proposal?.schema || '']
        },
        {
            key: 'expires_at',
            title: 'Fecha de vigencia',
            render: proposal => formatDate(proposal?.expires_at),
        },
        {
            key: 'annual_interest',
            title: 'Tasa de interés (anual)',
            render: proposal => percentage(proposal?.annual_interest),
        },
        {
            key: 'commission_to_open',
            title: 'Comisión por apertura',
            render: proposal => percentage(proposal?.commission_to_open),
        },
        {
            key: 'max_approved_amount',
            title: 'Valor del inmueble máximo',
            render: proposal => currencyString(proposal?.max_approved_amount),
        },
        {
            key: 'max_loan_capacity_percentage',
            title: 'Aforo máximo a financiar',
            render: proposal => percentage(proposal?.max_loan_capacity_percentage),
        },
        {
            key: 'years',
            title: 'Plazo',
            render: proposal => `${proposal?.years} años`,
        },
    ]