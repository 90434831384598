import { Route } from "src/types";
import { privateRoutes } from "./private";
import { publicRoutes } from "./public";
import { Navigate, useLocation } from "react-router-dom";
import { StartApplication } from "src/pages";

export function getRoutes(isAuth: boolean): Route[] {
    return [
        isAuth ? {
            path: '/',
            component: function Component() {
                const location = useLocation()
                return <Navigate to={`/home/main${location.search}`} />
            },
        } : {
            path: '/',
            component: StartApplication
        },
        ...publicRoutes,
        ...privateRoutes
    ]
}