import { ChangeEvent, useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { TextField, MessagePage, Loading } from "src/components";
import { LoginAlternatives } from "./Alternatives";
import { useIsMobile, segment } from "src/helpers";
import { useStyles } from "./styles";
import MagicLinkIcon from "src/assets/img/magic-link-send.svg";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "src/store/auth/actions";

export function MagicLink() {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const isSegmentLoaded = useSelector(state => state.auth.isSegmentLoaded)
    const isLoading = useSelector(state => state.customer.isLoading)
    const organization = useSelector(state => state.organization);

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: { email: '' },
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(authActions.sendMagicLink(values.email))
            segment.track('Customer', 'Clicked', {
                action: 'Send Magick Link',
                view: 'MagicLink',
                email: values.email
            })
        },
        validationSchema: yup.object().shape({
            email: yup.string().email('Debe ser un email válido').required('Ingresa un email')
        })
    });

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        const _value = value?.toLowerCase();
        handleChange({ ...event, target: { name, value: _value } });
    }

    useEffect(() => {
        if (isSegmentLoaded) {
            segment.page('MagicLink');
        }
    }, [window.analytics])

    return (
        <Loading isLoading={isLoading} className={classes.loadingContainer}>
            <MessagePage
                icon={MagicLinkIcon}
                title={`Ingresa a ${organization.name || ''} fácilmente`}
                message={`Te enviaremos un link mágico para ingresar a ${organization.name || ''} sin necesidad de contraseña`}
                containerProps={{
                    className: classes.subContainer
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <TextField
                                label="Correo"
                                name="email"
                                autoComplete="email"
                                type="email"
                                value={values.email}
                                onChange={onChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.email && touched.email)}
                                helperText={Boolean(errors.email && touched.email) && errors.email}
                            />
                            <div className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    data-cy="submit-button"
                                    className={classes.button}
                                    onClick={() => handleSubmit()}
                                    fullWidth={isMobile}
                                >
                                    Enviar link mágico
                                </Button>
                            </div>
                        </form>
                    </Grid>

                    {organization.login_alternatives.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Grid container className={classes.dividerContainer}>
                                    <Grid item xs={5}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography align="center">ó</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <LoginAlternatives />
                            </Grid>
                        </>
                    )}

                </Grid>
            </MessagePage>
        </Loading>
    )
}