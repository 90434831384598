import { GridSize } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { activityTypes, checkingMethods, currencyString, paymentFrequencies, salarySchemes, schemeTypes, translateEnumAnswer } from "src/helpers";
import { AddressType, CatalogType, Employment, EmploymentResourceType, Field, PhoneType, SelectValueType } from "src/types";

export function getEmploymentFields({
    salaryScheme,
    active,
    predetermined,
    resourceType,
    catalogs,
}: {
    salaryScheme: string,
    predetermined: any,
    active: any,
    resourceType?: EmploymentResourceType,
    catalogs?: {
        salary_scheme?: CatalogType[]
        activity_type?: CatalogType[]
        income_source?: CatalogType[]
        economic_activity_sat?: CatalogType[]
        economic_activity_cnbv?: CatalogType[]
        profession?: CatalogType[]
    }
}) {
    const fields = resourceType === 'creditParticipant'
        ? [...creditParticipantEmploymentFields({ ...catalogs })]
        : [...employmentFields]

    const index = fields.findIndex(f => f.key == 'ends_on');
    if (index > -1) {
        fields[index].disabled = active
    }

    if (resourceType === 'creditParticipant') {
        return fields
    }

    switch (salaryScheme) {
        case 'assalaried':
            return [
                ...fields,
                ...assalariedFields
            ]

        case 'freelance':
        case 'businessman':
            return [
                ...fields,
                ...businessmanFields
            ]

        default:
            return fields
    }
}

let employmentFields: Field[] = [
    {
        label: "¿Cómo compruebas tus ingresos?",
        // @ts-ignore
        field_type: "salary_scheme_field",
        key: 'salary_scheme',
        options: salarySchemes,
        row: 1,
        width: 6,
        sort_order: 1,
        disabled: false,
        required: true,
    },
    // {
    //     label: "Fecha de inicio",
    //     field_type: "date",
    //     key: 'starts_on',
    //     row: 2,
    //     width: 3,
    //     sort_order: 1,
    //     config: {
    //         variant: "picker"
    //     },
    // },
    // {
    //     label: "Fecha final",
    //     field_type: "date",
    //     key: 'ends_on',
    //     sort_order: 2,
    //     config: {
    //         variant: "picker"
    //     },
    //     row: 2,
    //     width: 3
    // },
    // {
    //     label: "Empleo actual",
    //     // @ts-ignore
    //     field_type: "custom_binary",
    //     key: 'active',
    //     row: 2,
    //     width: 3,
    //     sort_order: 3,
    //     fieldContainerProps: {
    //         style: {
    //             display: 'flex',
    //             alignItems: 'center'
    //         }
    //     }
    // },
    // {
    //     label: "Ingreso bruto mensual",
    //     field_type: "currency",
    //     key: 'before_taxes_salary',
    //     row: 4,
    //     width: 6
    // },
    {
        label: "¿Cuál es tu ingreso neto promedio mensual (después de impuestos)?",
        field_type: "currency",
        key: 'net_salary',
        row: 4,
        width: 6,
        required: true
    },
    // {
    //     label: "Dirección asociada al empleo",
    //     field_type: "address_field",
    //     key: 'related_address',
    //     row: 5,
    //     width: 12,
    // },
    {
        label: "¿Cómo buscas comprobar tus ingresos?",
        field_type: "select_field",
        key: 'checking_method',
        options: checkingMethods,
        row: 6,
        width: 6,
        sort_order: 2,
        required: true,
    },
    // {
    //     label: "Predeterminado",
    //     //@ts-ignore
    //     field_type: "predetermined_binary",
    //     key: 'predetermined',
    //     row: 10,
    //     width: 6,
    //     sort_order: 3,
    //     fieldContainerProps: {
    //         style: {
    //             display: 'flex',
    //             alignItems: 'center'
    //         }
    //     }
    // },
]

let creditParticipantEmploymentFields = ({
    salary_scheme,
    activity_type,
    income_source,
    economic_activity_cnbv,
    economic_activity_sat,
    profession,
}: {
    salary_scheme?: CatalogType[]
    activity_type?: CatalogType[]
    income_source?: CatalogType[]
    economic_activity_sat?: CatalogType[]
    economic_activity_cnbv?: CatalogType[]
    profession?: CatalogType[]
}): Field[] => [
        {
            label: "Esquema *",
            field_type: "select_field",
            key: 'salary_scheme',
            options: salary_scheme?.map(scheme => ({
                value: scheme?.external_id,
                caption: scheme?.description
            })),
            row: 1,
            width: 6,
            sort_order: 1,
            disabled: false,
            required: true,
        },
        {
            label: "Fecha de inicio",
            field_type: "date",
            key: 'starts_on',
            row: 2,
            width: 3,
            sort_order: 2,
            config: {
                variant: "picker"
            },
        },
        {
            label: "Fecha final",
            field_type: "date",
            key: 'ends_on',
            sort_order: 3,
            config: {
                variant: "picker"
            },
            row: 2,
            width: 3
        },
        {
            label: "Empleo actual",
            // @ts-ignore
            field_type: "custom_binary",
            key: 'predetermined',
            row: 2,
            width: 3,
            sort_order: 4,
            fieldContainerProps: {
                style: {
                    display: 'flex',
                    alignItems: 'center'
                }
            }
        },
        {
            label: "Ingreso bruto mensual",
            field_type: "currency",
            key: 'before_taxes_salary',
            row: 4,
            width: 6
        },
        {
            label: "Ingreso neto mensual *",
            field_type: "currency",
            key: 'net_salary',
            row: 4,
            width: 6,
            required: true
        },
        {
            label: "Tipo de actividad *",
            field_type: "select_field",
            key: 'activity_type',
            row: 5,
            width: 6,
            required: true,
            options: activity_type?.map(activity => ({
                value: activity?.external_id,
                caption: activity?.description
            })),
        },
        {
            label: "Fuente de ingresos *",
            field_type: "select_field",
            key: 'income_source',
            row: 5,
            width: 6,
            required: true,
            options: income_source?.map(source => ({
                value: source?.external_id,
                caption: source?.description
            })),
        },
        {
            label: "Profesión *",
            //@ts-ignore
            field_type: "autocomplete2",
            key: 'profession',
            row: 6,
            width: 6,
            required: true,
            options: profession?.map(profession => ({
                value: profession?.external_id,
                caption: profession?.name
            })),
        },
        {
            label: "Posición *",
            field_type: "text_field",
            key: 'position',
            row: 6,
            width: 6,
            required: true
        },
        {
            label: "Actividad económica (SAT) *",
            //@ts-ignore
            field_type: "autocomplete2",
            key: 'economic_activity_sat',
            row: 7,
            width: 6,
            required: true,
            options: economic_activity_sat?.map(activity => ({
                value: activity?.external_id,
                caption: activity?.description
            })),
        },
        {
            label: "Actividad económica (CNBV) *",
            //@ts-ignore
            field_type: "autocomplete2",
            key: 'economic_activity_cnbv',
            row: 7,
            width: 6,
            required: true,
            options: economic_activity_cnbv?.map(activity => ({
                value: activity?.external_id,
                caption: activity?.description
            })),
        },
        {
            label: "Teléfono",
            field_type: "phone",
            key: 'default_phone',
            row: 8,
            width: 6,
            required: false,
        },
    ]

const assalariedFields: Field[] = [
    // {
    //     label: "Esquema",
    //     field_type: "select_field",
    //     key: 'activity_type',
    //     options: schemeTypes,
    //     row: 1,
    //     width: 6,
    //     sort_order: 1,
    // },
    // {
    //     label: "Razón social",
    //     field_type: "text_field",
    //     key: 'company_name',
    //     row: 3,
    //     width: 6
    // },
    {
        label: "Frecuencia de pago",
        field_type: "radio_buttons",
        key: 'payment_frequency',
        options: paymentFrequencies,
        config: {
            variant: 'outlined',
            row: false
        },
        row: 6,
        width: 6,
        sort_order: 1
    },
    // {
    //     label: "Puesto",
    //     field_type: "text_field",
    //     key: 'position',
    //     row: 3,
    //     width: 6
    // },
]

const businessmanFields: Field[] = [
    // {
    //     label: "Clasificación de la actividad",
    //     field_type: "select_field",
    //     key: 'activity_type',
    //     options: activityTypes,
    //     row: 3,
    //     width: 6
    // },
    // {
    //     label: "Factor de afectación",
    //     field_type: "number",
    //     key: 'affect_factor',
    //     row: 3,
    //     width: 6,
    // },
]

interface EmploymentColumnType {
    value: keyof Employment,
    label: string,
    width?: GridSize,
    render?: (value: any) => string
}

export function getEmploymentColumns(salaryScheme?: string, address?: AddressType, resourceType?: EmploymentResourceType): EmploymentColumnType[] {

    if (resourceType === 'creditParticipant') {
        return creditParticipantEmploymentColumns
    }

    switch (salaryScheme) {
        case 'assalaried':
            return [
                ...employmentColumns,
                ...employmentAssalariedColumns
            ]

        case 'freelance':
        case 'businessman':
            return [
                ...employmentColumns,
                ...employmentBusinessmanColumns
            ]

        default:
            return employmentColumns
    }
}

const employmentColumns: EmploymentColumnType[] = [
    {
        value: 'salary_scheme',
        label: 'Regimen',
        render: (value: any) => translateEnumAnswer(value)
    },
    {
        value: 'starts_on',
        label: 'Fecha de inicio',
        render: (value: any) => value ? moment(value, 'YYYY-MM-DD').format('DD/MMM/YYYY') : ''
    },
    {
        value: 'ends_on',
        label: 'Fecha final',
        render: (value: any) => value ? moment(value, 'YYYY-MM-DD').format('DD/MMM/YYYY') : ''
    },
    {
        value: 'active',
        label: 'Empleo actual',
        render: (value: any) => Boolean(value) ? 'Sí' : 'No'
    },
    {
        value: 'before_taxes_salary',
        label: 'Ingreso bruto mensual',
        render: (value: any) => currencyString(value)
    },
    {
        value: 'net_salary',
        label: 'Ingreso neto mensual',
        render: (value: any) => currencyString(value)
    },
    {
        value: 'checking_method',
        label: 'Método de comprobación',
        width: 6,
        render: (values: any[]) => _.isArray(values) ? `${values.map(value => ' ' + translateEnumAnswer(value))}` : values
    },
]

const creditParticipantEmploymentColumns: EmploymentColumnType[] = [
    {
        value: 'salary_scheme',
        label: 'Esquema',
        render: (value: any) => value?.description
    },
    {
        value: 'starts_on',
        label: 'Fecha de inicio',
        render: (value: any) => value ? moment(value, 'YYYY-MM-DD').format('DD/MMM/YYYY') : ''
    },
    {
        value: 'ends_on',
        label: 'Fecha final',
        render: (value: any) => value ? moment(value, 'YYYY-MM-DD').format('DD/MMM/YYYY') : ''
    },
    {
        value: 'predetermined',
        label: 'Empleo actual',
        render: (value: any) => Boolean(value) ? 'Sí' : 'No'
    },
    {
        value: 'before_taxes_salary',
        label: 'Ingreso bruto mensual',
        render: (value: any) => currencyString(value)
    },
    {
        value: 'net_salary',
        label: 'Ingreso neto mensual',
        render: (value: any) => currencyString(value)
    },
    {
        value: 'phones',
        label: 'Teléfono',
        render: (value?: PhoneType[]) => {
            const defaultPhone = value?.find(n => n.default)
            return defaultPhone ? `+${defaultPhone.code_area} ${defaultPhone.number}` : ''
        }
    },
]

const employmentAssalariedColumns: EmploymentColumnType[] = [
    {
        value: 'activity_type',
        label: 'Esquema',
        render: (value: any) => translateEnumAnswer(value)
    },
    {
        value: 'company_name',
        label: 'Razón social',
    },
    {
        value: 'payment_frequency',
        label: 'Frecuencia de pago',
        render: (value: any) => translateEnumAnswer(value)
    },
    {
        value: 'position',
        label: 'Puesto',
    },
]

const employmentBusinessmanColumns: EmploymentColumnType[] = [
    {
        value: 'activity_type',
        label: 'Clasificación de la actividad',
        render: (value: any) => translateEnumAnswer(value)
    },
    {
        value: 'affect_factor',
        label: 'Factor de afectación',
    },
]

export function parseCheckingMethods(value?: string[]): SelectValueType[] {
    return checkingMethods.filter(cM => value?.includes(cM.value))
}