import React from 'react'
import { IconButton, Button } from '@mui/material'
import { PDF2Icon, XLSXIcon } from 'src/assets/icons'
import { useIsMobile } from 'src/helpers'
import { useStyles } from './DownloadOptions.styles'

interface DownloadOptionsProps {
    productApplicationId?: string
    organizationId: string
}

export function DownloadOptions({ productApplicationId, organizationId }: DownloadOptionsProps) {
    const { classes, cx } = useStyles()
    const isMobile = useIsMobile()
    const [isDownloading, setIsDownloading] = React.useState(false)

    async function handleDownload(productApplicationId?: string, format?: 'xlsx' | 'pdf') {
        if (!productApplicationId) return

        try {
            setIsDownloading(true)

            const url = format === 'xlsx'
                ? `${import.meta.env.REACT_APP_ABACUS_URL}/product_applications/${productApplicationId}/render_excel`
                : `${import.meta.env.REACT_APP_BASE_URL}/onboarding/product_applications/${productApplicationId}.pdf?organization_id=${organizationId}`

            const filename = format === 'xlsx'
                ? 'Tabla de amortización.xlsx'
                : 'Tabla de amortización.pdf'

            const response = await fetch(url)
            const blob = await response.blob()

            const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

        } catch {

        } finally {
            setIsDownloading(false)
        }

    }

    return (
        <div className={classes.buttonsContainer}>
            {isMobile ? (
                <>
                    <IconButton
                        size='small'
                        className={cx(classes.iconButton, {
                            [classes.disabledButton]: !productApplicationId || isDownloading
                        })}
                        disabled={!productApplicationId || isDownloading}
                        onClick={() => handleDownload(productApplicationId, 'xlsx')}
                    >
                        <XLSXIcon />
                    </IconButton>

                    <IconButton
                        size='small'
                        className={cx(classes.iconButton, {
                            [classes.disabledButton]: !productApplicationId || isDownloading
                        })}
                        disabled={!productApplicationId || isDownloading}
                        onClick={() => handleDownload(productApplicationId, 'pdf')}
                    >
                        <PDF2Icon />
                    </IconButton>
                </>
            ) : (
                <>
                    <Button
                        size="small"
                        startIcon={<XLSXIcon />}
                        className={cx(classes.button, {
                            [classes.disabledButton]: !productApplicationId || isDownloading
                        })}
                        disabled={!productApplicationId || isDownloading}
                        onClick={() => handleDownload(productApplicationId, 'xlsx')}
                    >
                        Descargar Excel
                    </Button>

                    <Button
                        size="small"
                        startIcon={<PDF2Icon />}
                        className={cx(classes.button, {
                            [classes.disabledButton]: !productApplicationId || isDownloading
                        })}
                        disabled={!productApplicationId || isDownloading}
                        onClick={() => handleDownload(productApplicationId, 'pdf')}
                    >
                        Descargar PDF
                    </Button>
                </>
            )}
        </div>
    )
}