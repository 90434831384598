import { Route } from "react-router-dom";
import { Topbar } from "./Topbar";
import { getRoutes } from "src/routes";
import { useStyles } from "./styles";
import { PrivateRoute, Routes, Snackbar } from "src/components";
import { cookies, getCookieName } from "src/helpers";
import { NotFound } from "src/pages";
import { MessageDialog } from "src/components";

export function Layout() {
    const { classes, cx } = useStyles();

    return (
        <div className={classes.root}>

            {window.location.pathname !== '/expired-link' && (
                <Topbar />
            )}

            <div className={classes.content}>
                <Routes
                    routes={getRoutes(cookies.get(getCookieName()))}
                    notFoundPage={NotFound}
                />
            </div>

            <Snackbar />

            <MessageDialog />

        </div>
    )
}