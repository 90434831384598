import Bugsnag from '@bugsnag/js';
import { Action, ErrorType } from "src/types";

import { call, put, all } from "redux-saga/effects";
import { snackbarActions } from "src/store/snackbar/actions";
import { workflowActions } from "src/store/workflow/actions";

import _ from "lodash";
import { navigate } from './navigation';

/*  SAFE SAGA CALL FOR CENTRALIZED ERROR HANDLING */
export function sagaWrapper(sagaFn: any, errorAction?: any, customErrorMessage?: string) {
    return function* (): any {
        try {

            return yield call(sagaFn, arguments[0]);

        } catch (error: any) {
            /** Params from saga function */
            const params: Action = arguments[0]

            try {

                if (error.message === "Operation canceled") {
                    return;
                }

                if (error.message === 'Network Error' && error.config?.baseURL?.includes('apollo')) {
                    yield navigate.to('/maintenance');
                    return;
                }

                /**
                 * Don't report errors from API requests
                 */
                if (!error.response?.status && error.message != 'Network Error') {
                    console.log("Error:", error)
                    Bugsnag.notify(error);
                }

                if (error.response?.data?.errors) {
                    const loginAgainMessage: string = error.response?.data?.errors?.find((error: any) => typeof (error) === 'object' ? false : error.includes('Debes iniciar sesión'));
                    if (loginAgainMessage) {
                        yield navigate.to('/login_again');
                        const splitted = loginAgainMessage.split(' ');
                        yield put(workflowActions.loginAgain(splitted[splitted.length - 1]));
                    } else {

                        yield all(error.response?.data?.errors?.map((error: any) => {
                            if (typeof (error) === 'object') {
                                return put(snackbarActions.add('error', error.message))
                            } else {
                                return put(snackbarActions.add('error', error))
                            }
                        }));

                    }

                } else if (error.response?.data?.error && typeof (error.response?.data?.error) != 'string') {

                    const errorType: ErrorType = error.response?.data?.error;

                    /**
                     * If has custom error code, do specfic action and no show server messages
                     */
                    if (errorType.code && errorType.code != 'ERROR') {

                        yield put({ type: `CUSTOM/${errorType.code}`, payload: params.payload })

                    } else {
                        if (_.isArray(errorType.messages)) {

                            yield all(errorType.messages.map((error: any) => {
                                if (typeof (error) === 'object') {
                                    return put(snackbarActions.add(errorType.type || 'error', error.message))
                                } else {
                                    return put(snackbarActions.add(errorType.type || 'error', error))
                                }
                            }));

                        } else {

                            if (typeof (errorType.messages) === 'object') {
                                return put(snackbarActions.add(errorType.type || 'error', error.message))
                            } else {
                                return put(snackbarActions.add(errorType.type || 'error', error))
                            }

                        }

                    }

                } else {
                    if (customErrorMessage) {
                        yield put(snackbarActions.add('error', customErrorMessage));
                    } else {
                        yield put(snackbarActions.add('error', 'Lo sentimos, ha ocurrido un error'));
                    }
                }

                if (errorAction && error.message !== "Operation canceled") {
                    yield put(errorAction)
                }

            } catch (error: any) {
                console.log("Error handler error:", error)
                Bugsnag.notify(error);
            }

        }
    }
}
