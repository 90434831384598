import { Fragment } from "react"
import { Button, Grid } from "@mui/material"
import { Link } from "src/components"
import { useApplicationStage, useIsKredi, useIsMobile } from "src/helpers"

import { MainSmallCard } from "../components"
import { mainCardItems } from "../mainCardsItems"

import { useDispatch, useSelector } from "react-redux"
import { applicationActions } from "src/store/application/actions"

import { useStyles } from "./styles"
import _ from "lodash"

interface ApplicationCardsMenuProps {
    setCancelApplicationOpen: (open: boolean) => void
    setCoaccreditOpen: (open: boolean) => void
}

export function ApplicationCardsMenu({
    setCancelApplicationOpen,
    setCoaccreditOpen
}: ApplicationCardsMenuProps) {
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const isKredi = useIsKredi()

    const {
        isPriority,
        canContinue,
        currentSectionLink
    } = useApplicationStage({ useLastOneApplication: true })

    const application = useSelector(state => state.applications?.lastApplication || {})
    const relatedApplications = useSelector(state => state.relatedApplications.data || [])
    const creditProposals = useSelector(state => state.creditProposals.data)

    const menuItems = mainCardItems({
        application,
        isPriority,
        canContinue,
        currentSectionLink,
        hasApplicationParent: Boolean(application?.parent?.application_workflow_id),
        relatedApplication: relatedApplications?.[0],
        setCancelApplicationOpen,
        setCoaccreditOpen,
        isKredi,
        hasCreditProposals: Boolean(creditProposals?.length),
        continueRelatedApplication: (relatedApplicationId: string) => {
            dispatch(applicationActions.continueApplication(relatedApplicationId))
        }
    }).filter(mc => !Boolean(mc?.hidden))

    return (
        <Grid
            container
            alignItems="stretch"
            className={classes.smallCardsContainer}
            spacing={!isMobile ? 4 : 2}
        >
            {_.map(menuItems, (item, index) => {
                const { isExternalLink, linkTo, linkTitle, image: Icon, title, description, disabled, onClick } = item
                const LinkComponent = isExternalLink ? 'a' : (onClick ? Fragment : Link);

                const linkProps = isExternalLink ? {
                    href: linkTo,
                    target: '_blank',
                    rel: 'noreferrer',
                    className: classes.link
                } : {
                    to: linkTo,
                    native: linkTo.includes('http'),
                    onClick: () => {
                        if (onClick) {
                            onClick()
                        }
                    }
                }

                return (
                    <MainSmallCard
                        key={index}
                        Icon={Icon}
                        title={title}
                        description={description}
                        actionElement={(
                            <LinkComponent {...linkProps as any} disabled={disabled}>
                                <Button
                                    variant="text"
                                    color="primary"
                                    className={classes.button}
                                    disabled={disabled}
                                    onClick={() => {
                                        if (onClick) {
                                            onClick()
                                        }
                                    }}
                                >
                                    {linkTitle}
                                </Button>
                            </LinkComponent>
                        )}
                        rootGridProps={{
                            item: true,
                            container: true,
                            xs: 6,
                            lg: 3,
                        }}
                    />
                )
            })}
        </Grid>
    )
}