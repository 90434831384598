import { Grid, Typography } from "@mui/material";
import { useStyles } from "./styles"
import InReviewImg from "src/assets/img/application_in_review.svg"
import { ConsultantCard } from "../components";
import { NextStepsCard } from "../components/NextStepsCard";
import { Calculator } from "../components/Calculator";

export function InReviewPage() {
	const { classes, cx } = useStyles();

	return (
		<>
			<Grid item xs={12} className={classes.hero}>
				<div className={classes.heroTexts}>
					<Typography variant="h3"> Estamos revisando tu expediente </Typography>
					<Typography> Un ejecutivo va a revisar tu expediente y en unas horas te informaremos sobre tus resultados vía mensaje de texto y correo electrónico. </Typography>
				</div>
				<div className={classes.image}>
					<InReviewImg />
				</div>
			</Grid>
			{/* <Grid item xs={12}>
				<ConsultantCard
					text="Asesoría"
					applicationOrigin="current"
					showDelayedLoader
				/>
			</Grid> */}
			<Grid item xs={12}>
				<NextStepsCard />
			</Grid>
			{/* <Grid item xs={12}>
				<Calculator />
			</Grid> */}
		</>
	)
}