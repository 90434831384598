import { currencyString } from "./format"

/**
 * Returns summary of the collection by given object property
 * @param data 
 * @param property 
 */
export function getTotal<T, K extends keyof T>(data: T[], property: K, noFormat?: boolean): string | number {
    if (!data) return ''
    const total = data?.reduce((accumulator, currentValue) => accumulator + Number(currentValue[property]), 0)
    return noFormat ? total : currencyString(total)
}