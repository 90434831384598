
export const salarySchemes = [
    { value: "assalaried", caption: "Asalariado" },
    { value: "freelance", caption: "Profesionista Independiente o Empresario" },
    { value: "businessman", caption: "Recibo dividendos de mi empresa (utilidades)" },
    { value: "cash", caption: "Recibo dinero en Efectivo (No tengo depósitos bancarios)" },
]

export const paymentFrequencies = [
    { caption: 'Semanal', value: 'weekly' },
    // { caption: 'Bisemanal', value: 'biweekly' },
    { caption: 'Quincenal', value: 'fortnightly' },
    { caption: 'Mensual', value: 'monthly' },
]

export const checkingMethods = [
    { caption: 'Nómina', value: 'paysheet' },
    { caption: 'Estado de cuenta', value: 'statement' },
    { caption: 'Declaración de impuestos', value: 'tax_return' },
]

export const activityTypes = [
    { caption: 'Arrendamiento', value: 'leasing' },
    { caption: 'Comercio', value: 'commerce' },
    { caption: 'Servicios', value: 'services' },
    { caption: 'Manufactura', value: 'manufacture' },
    { caption: 'Transporte', value: 'transportation' },
    { caption: 'Gasolineros', value: 'gas_stations' },
]

export const schemeTypes = [
    { caption: 'Nómina tradicional', value: 'traditional_payroll' },
    { caption: 'Asimilados a salarios', value: 'assimilated_to_salary' },
]