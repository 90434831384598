import { all } from 'redux-saga/effects';
import { authSagas } from './auth/sagas';
import { organizationSagas } from "./organization/sagas";
import { customerSagas } from "./customer/sagas";
import { workflowSagas } from "./workflow/sagas";
import { productApplicationSagas } from "./productApplication/sagas";
import { customActionsSagas } from './custom/sagas';
import { questionSaga } from './questions/sagas';
import { sectionSagas } from './sections/sagas';
import { stageSagas } from './stages/sagas';
import { providersSagas } from './providers/sagas';
import { relatedApplcationsSagas } from './relatedApplications/sagas';
import { attachmentsSagas } from './attachments/sagas';
import { employmentsSagas } from './employments/sagas';
import { bankAccountsSagas } from './bankAccounts/sagas';
import { creditProposalsSagas } from './creditProposals/sagas';
import { defaultWorkflowsSagas } from './defaultWorkflows/sagas';

import {
    applicationSagas,
    applicationRejectedDocumentsSagas,
    applicationCategoriesSagas,
    applicationEvaluatorFieldsSagas,
} from './application/sagas';

import {
    creditsSagas,
    paymentsSagas,
    participantsSagas,
    amortizationTablesSagas,
    accountStatementsSagas,
    directsDebitSagas,
} from './credits/sagas';

export function* rootSaga() {
    yield all([
        authSagas(),
        organizationSagas(),
        customerSagas(),
        workflowSagas(),
        productApplicationSagas(),
        applicationSagas(),
        questionSaga(),
        sectionSagas(),
        stageSagas(),
        providersSagas(),
        customActionsSagas(),
        applicationCategoriesSagas(),
        applicationEvaluatorFieldsSagas(),
        applicationRejectedDocumentsSagas(),
        relatedApplcationsSagas(),
        attachmentsSagas(),
        creditsSagas(),
        paymentsSagas(),
        participantsSagas(),
        amortizationTablesSagas(),
        accountStatementsSagas(),
        directsDebitSagas(),
        employmentsSagas(),
        bankAccountsSagas(),
        creditProposalsSagas(),
        defaultWorkflowsSagas()
    ]);
}