import { ComponentType } from "react";
import ProfileAddressImg from 'src/assets/img/profile_address.svg';
import ProfileContactImg from 'src/assets/img/profile_contact.svg';
import ProfileEmploymentsImg from 'src/assets/img/profile_employments.svg';
import ProfilePersonalImg from 'src/assets/img/profile_personal.svg';
import { MainCardItem } from "src/pages/Home/Main/mainCardsItems";

export function cardItems(): MainCardItem[] {
	return ([
		{
			image: ProfilePersonalImg,
			title: 'Datos personales',
			description: 'Consulta tus datos personales.',
			linkTitle: 'Ir a datos',
			linkTo: '/home/profile/personal-data'
		},
		{
			image: ProfileContactImg,
			title: 'Contacto',
			description: 'Consulta tu información de contacto.',
			linkTitle: 'Ir a contacto',
			linkTo: '/home/profile/contact-data'
		},
		{
			image: ProfileAddressImg,
			title: 'Direcciones',
			description: 'Edita o agrega una dirección',
			linkTitle: 'Ir a direcciones',
			linkTo: '/home/profile/addresses'
		},
		// {
		// 	image: ProfileEmploymentsImg,
		// 	title: 'Empleos',
		// 	description: 'Edita o agrega un empleo.',
		// 	linkTitle: 'Ir a empleos',
		// 	linkTo: '/home/profile/employments'
		// },
	]);
}