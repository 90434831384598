import { numberWithCommas } from "src/helpers";
import moment from "moment";
import _ from 'lodash'
import { formatPhone } from "./formatPhone";
import { FieldType } from "src/types";

export const hiddenFields = ["text_help"]


export function parseAnswer(fieldType: FieldType, value: any, config?: any) {
	let _value = value
	switch (fieldType) {
		case 'date':
			return moment(value).format('DD/MMM/YY')
		case 'gender':
			return value === 'male' ? 'Hombre' : 'Mujer'
		case 'binary':
			return value ? 'Sí' : 'No'
		case 'radio_buttons':
		case 'select_field':
			const option = config?.options?.find((option: any) => option.value === value);
			return option?.caption || value;
		case 'currency':
			return `$${numberWithCommas(Number(value))}`
		case 'phone_confirmation':
			_value = JSON.parse(value)
			return formatPhone(`${_value?.phone_country_code || ''} ${_value?.phone || ''}`)
		case 'phone':
			return formatPhone(`${value?.phone_country_code || ''} ${value?.phone || ''}`)
		//return `${value?.phone_country_code || ''} ${value?.phone || ''}`
		case 'password':
			return value ? '********' : ''
		case 'terms_and_conditions':
			return value ? 'Aceptados' : 'No aceptados';
		case 'document_verification':
			return value ? 'Verificación realizada' : 'Verificación no realizada'
		case 'multi_bank_account':
		case 'incode_document_id':
		case 'incode_proof_of_address':
		case 'incode_proof_of_income':
		case 'bank_statement':
		case 'tax_id_status':
			return (value == 't' || value === true) ? 'Documentos cargados' : 'No se cargaron documentos'
		case 'address_autocomplete':
			_value = typeof value === 'string' ? JSON.parse(value) : value
			const fullAddress = `${_value?.street || ''} ${_value?.street_number || ''}${_value?.interior_number ? ' ' + _value?.interior_number : ''}, ${_value?.neighborhood}, ${_value?.zip || ''} ${_value?.city || ''}, ${_value?.state || ''}, ${_value.country}`
			return fullAddress || value

		default:
			return value
	}
}