import { useState } from "react"
import { ConfirmModal } from "src/components"
import { ActionableType } from "src/types"
import { isValidId } from "src/helpers"
import { DocumentsSection, MenuActionProps, SectionCardProps } from "../../components"
import PerfiIcon from "src/assets/img/home/documents/perfil.svg"

import { useDispatch, useSelector } from "react-redux"
import { applicationEvaluatorFieldsActions } from "src/store/application/actions"

import { filterApprovals } from "../../components/DocumentsFlow/helpers"
import { personalActionableTypes } from "./helpers"
import { DocumentSectionProps } from ".."

export function PersonalDocumentsSection({ applicationId, openDocumentsDialog, setDocumentsDialog }: DocumentSectionProps) {
    const dispatch = useDispatch()

    const customer = useSelector(state => state.auth.customer)
    // const documents = useSelector(state => state.applications.rejectedDocuments?.data) || []
    const documents = useSelector(state => state.applications.switchableEvaluators?.approvals || [])

    const [destroyConfirmation, setDestroyConfirmation] = useState<{
        open: boolean,
        actionableId?: string,
        actionableType?: ActionableType
    }>({ open: false })

    const personalApprovals = filterApprovals(documents, personalActionableTypes)

    const personalCardsItems: SectionCardProps[] = personalApprovals.length <= 0 ? [] : [
        {
            title: 'Documentos Personales',
            actionTitle: 'Cargar documentos',
            approvals: personalApprovals,
            Icon: PerfiIcon,
            hideMenuActions: true,
            handleAction: () => openDocumentsDialog({
                actionableTypes: personalActionableTypes,
                showOnlyDocuments: true
            })
        }
    ]

    const employmentsIds = documents.filter((doc, index, array) =>
        doc?.actionable_type === 'Employment' &&
        array.findIndex(d => d?.actionable_id === doc?.actionable_id) === index
    ).map(doc => doc?.actionable_id || '')

    const employmentsCardsItems: SectionCardProps[] = employmentsIds.map((id, index) => ({
        actionableId: id,
        actionableType: 'Employment',
        title: `Empleo ${index + 1}`,
        actionTitle: 'Cargar documentos',
        approvals: filterApprovals(documents, ['Employment'], id),
        handleAction: () => openDocumentsDialog({
            actionableId: id,
            actionableTypes: ['Employment'],
            showOnlyDocuments: true
        })
    }))

    const actions: MenuActionProps[] = [
        {
            title: 'Eliminar',
            handleAction(actionableId, actionableType) {
                if (isValidId(actionableId)) {
                    setDestroyConfirmation({
                        open: true,
                        actionableId: actionableId,
                        actionableType: actionableType
                    })
                }
            },
        },
        {
            title: 'Editar',
            handleAction(actionableId, actionableType) {
                if (isValidId(actionableId)) {
                    dispatch(applicationEvaluatorFieldsActions.getActionable(actionableId!, actionableType!))

                    setDocumentsDialog({
                        open: true,
                        showOnlyDocuments: false,
                        types: ['Employment']
                    })
                }
            },
        }
    ]

    return (
        <>
            <ConfirmModal
                open={destroyConfirmation.open}
                title="Eliminar empleo"
                description="¿Deseas eliminar este empleo?"
                confirmText="Sí, eliminar"
                cancelText="Volver"
                onConfirm={() => {
                    dispatch(applicationEvaluatorFieldsActions.executeEvaluators(
                        'delete',
                        applicationId,
                        destroyConfirmation.actionableType!,
                        { id: destroyConfirmation.actionableId }
                    ))

                    setDestroyConfirmation({ open: false })
                }}
                onCancel={() => setDestroyConfirmation({ open: false })}
            />

            <DocumentsSection
                title={`Acreditado Principal | ${customer?.first_name || ''} ${customer?.last_name || ''}`}
                items={[...personalCardsItems, ...employmentsCardsItems]}
                // sectionCardProps={{ actions }}
                addButtonProps={{
                    show: false,
                    text: 'Agregar otro empleo',
                    onClick: () => {
                        dispatch(applicationEvaluatorFieldsActions.setActionable({ actionableType: 'Employment' }))
                        setDocumentsDialog({ open: true, types: ['Employment'] })
                    }
                }}
            />
        </>
    )
}