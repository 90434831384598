import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { AmortizationChart, AmortizationTable, DonutChart } from "@kredimx/abacus-components"
import { Grid, Typography } from "@mui/material"
import { EmptyState, Loading, StagePage, TitleCard } from "src/components"
import { isValidId } from "src/helpers"

import { useDispatch, useSelector } from "react-redux"
import { applicationActions } from "src/store/application/actions"
import { creditProposalsActions } from "src/store/creditProposals/actions"

import { CreditProposalSection, CreditProposalSectionFields } from "./Section"
import { ActionsFooter } from "./Footer"
import { StagePageActions } from "./Action"

import { financialConditionsFields, getCreditDataFields, getProposalTermsAndConditions } from "./helpers"
import { useStyles } from "./styles"
import { productApplicationActions } from "src/store/productApplication/actions"

export function CreditProposalDetailsPage() {
    const { proposalId, applicationId }: any = useParams()
    const { classes } = useStyles()
    const dispatch = useDispatch()

    const application = useSelector(state => state.applications?.application)
    const creditProposal = useSelector(state => state.creditProposals?.creditProposal)

    const isLoadingApplication = useSelector(state => state.applications.isLoading)
    const isLoadingCreditProposal = useSelector(state => state.creditProposals.isLoading)

    const organization = useSelector(state => state.organization)
    const quotation = useSelector(state => state.productApplication.productApplication)
    const amortizationTable = useSelector(state => state.productApplication.amortizationTable)
    const donut = useSelector(state => state.productApplication.donut)
    const area = useSelector(state => state.productApplication.area)
    const abacusProduct = useSelector(state => state.creditProposals.abacusProduct)

    const isLoading = isLoadingApplication || isLoadingCreditProposal

    useEffect(() => {
        if (isValidId(applicationId)) {
            if (applicationId !== application?.id) {
                dispatch(applicationActions.getApplication(applicationId))
            }

            if (isValidId(proposalId)) {
                dispatch(creditProposalsActions.getOne(applicationId, proposalId))
            }
        }
    }, [applicationId, proposalId])

    useEffect(() => {
        if (creditProposal?.product_application_id) {
            dispatch(productApplicationActions.getOne(creditProposal.product_application_id))
        }
    }, [creditProposal?.product_application_id])

    useEffect(() => {
        if (quotation?.last_amortization_table) {
            dispatch(productApplicationActions.getData('amortizationTable', quotation.last_amortization_table))
            dispatch(productApplicationActions.getData('donut', quotation.last_amortization_table))
            dispatch(productApplicationActions.getData('area', quotation.last_amortization_table))
        }
    }, [quotation?.last_amortization_table])

    useEffect(() => {
        return () => {
            // dispatch(creditProposalsActions.clear())
            // dispatch(productApplicationActions.cleanQuotation())
        }
    }, [])

    return (
        <StagePage
            title="Propuesta de crédito"
            action={<StagePageActions />}
            titleContainerProps={{
                style: { marginBottom: 32 }
            }}
            titleProps={{
                className: classes.stageTitle
            }}
            breadcrumbs={[
                { name: 'Inicio', to: '/home/main' },
                { name: 'Propuestas de crédito', to: `/home/main/${applicationId}/proposals` },
                { name: 'Detalles' },
            ]}
        >
            <Loading isLoading={isLoading} variant="skeleton">
                <Grid container>
                    <Grid item xs={12}>
                        <TitleCard title="Participantes del crédito">
                            <>
                                <CreditProposalSectionFields
                                    entity={creditProposal}
                                    style={{ marginBottom: 8 }}
                                    fields={[
                                        { label: 'Acreditado', key: 'customer_name', width: 4 },
                                        { label: 'RFC', key: 'customer_rfc', width: 4 },
                                    ]}
                                />

                                {creditProposal?.co_accredited_id && (
                                    <CreditProposalSectionFields
                                        entity={creditProposal}
                                        style={{ marginBottom: 8 }}
                                        fields={[
                                            { label: 'Coacreditado', key: 'co_accredited_name', width: 4 },
                                            { label: 'RFC', key: 'co_accredited_rfc', width: 4 },
                                        ]}
                                    />
                                )}
                            </>
                        </TitleCard>
                    </Grid>

                    <Grid item xs={12}>
                        <CreditProposalSection
                            title="Datos del crédito"
                            proposal={creditProposal}
                            fields={getCreditDataFields(false, abacusProduct?.name || '')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CreditProposalSection
                            title="Condiciones financieras"
                            proposal={creditProposal}
                            fields={financialConditionsFields}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TitleCard title="Tabla de amortización" containerProps={{ style: { paddingTop: 0 } }}>
                            {(!amortizationTable.data && !amortizationTable.isLoading) ? emptyState : (
                                <AmortizationTable
                                    hideTitle
                                    externalData={amortizationTable.data}
                                    externalLoading={amortizationTable.isLoading}
                                    showAllPeriods
                                    showTotals
                                    showCompleteTableButton
                                    containerProps={{
                                        className: classes.data
                                    }}
                                />
                            )}

                        </TitleCard>
                    </Grid>

                    <Grid item xs={12}>
                        <TitleCard title="Evolución del crédito">
                            {(!area.data && !area.isLoading) ? emptyState : (
                                <AmortizationChart
                                    hideTitle
                                    externalData={area.data}
                                    externalLoading={area.isLoading}
                                    containerProps={{
                                        className: classes.data
                                    }}
                                />
                            )}
                        </TitleCard>
                    </Grid>

                    <Grid item xs={12}>
                        <TitleCard title="Composición del crédito">
                            {(!donut.data && !donut.isLoading) ? emptyState : (
                                <DonutChart
                                    hideTitle
                                    externalData={donut.data}
                                    containerProps={{
                                        className: classes.data
                                    }}
                                />
                            )}
                        </TitleCard>
                    </Grid>

                    <Grid item xs={12}>
                        <TitleCard title="Términos y condiciones">
                            <Typography
                                className={classes.termsAndConditions}
                                dangerouslySetInnerHTML={{ __html: getProposalTermsAndConditions(organization.legal_name || '', creditProposal?.expires_at) }}
                            />
                        </TitleCard>
                    </Grid>
                </Grid>

                <ActionsFooter
                    applicationId={applicationId}
                    proposal={creditProposal}
                />
            </Loading>
        </StagePage >
    )
}

const emptyState = (
    <EmptyState
        style={{ marginTop: 0 }}
        size="small"
        text="Aún no hay información disponible"
    />
)