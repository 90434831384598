import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'fixed',
        bottom: 0,
        left: 238,
        padding: '16px 24px',
        textAlign: 'right',
        width: 'calc(100% - 238px)',
        borderTop: '1px solid #CFD5E2',
        zIndex: 0,
        '& > * + *': {
            marginLeft: 16
        },
        [theme.breakpoints.down('sm')]: {
            left: 0,
            width: '100%',
        }
    },
    helperText: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.warning.main,
        '& svg': {
            marginRight: 8
        }
    },
    helperSuccess: {
        color: theme.palette.success.main,
    },
}))