import { useEffect, useState } from "react"
import { IconButton } from "@mui/material"
import { ArrowBackIos as ArrowLeftIcon } from "@mui/icons-material"

import { Dialog, DialogProps, Loading, LoadingPageProps } from "src/components"
import { ActionableType } from "src/types"

import { useDispatch, useSelector } from "react-redux"
import { applicationEvaluatorFieldsActions, applicationRejectedDocuments } from "src/store/application/actions"

import { DocumentsFlow } from "../components"
import { EmploymentFormFlow } from "./Flows"

import { allowedStatusesToUpload, filterApprovals } from "../components/DocumentsFlow/helpers"
import { useStyles } from "./styles"

export interface DocumentsFlowDialogProps {
    dialogProps: DialogProps

    loadingProps?: LoadingPageProps

    /** Use to filter documents with actionables types */
    actionableTypes?: ActionableType[]

    /** If is true, not display the fields form, only documents flow */
    showOnlyDocuments?: boolean
}

/** Component to display a documents flow after submit a fields form */
export function DocumentsFlowDialog({
    dialogProps,
    loadingProps,
    showOnlyDocuments,
    actionableTypes,
}: DocumentsFlowDialogProps) {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const applicationId = useSelector(state => state.applications.application.id)

    const {
        isLoadingExecution,
        isLoadingActionable,
        actionable
    } = useSelector(state => state.applications.evaluatorFields)

    const {
        approvals = [],
        isLoading: isLoadingApprovals
    } = useSelector(state => state.applications.switchableEvaluators)

    const [showDocumentsFlow, setShowDocumentsFlow] = useState(false)
    const [currentFieldIndex, setCurrentFieldIndex] = useState(0)
    const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0)

    const currentIndex = showDocumentsFlow ? currentDocumentIndex : currentFieldIndex
    const setCurrentIndex = showDocumentsFlow ? setCurrentDocumentIndex : setCurrentFieldIndex
    const disableDialogClose = isLoadingExecution && showDocumentsFlow

    useEffect(() => {
        setShowDocumentsFlow(showOnlyDocuments || false)
    }, [showOnlyDocuments])

    useEffect(() => {
        // After finish evaluators executions and load rejected documents check if generated documents to upload,
        // if not, closes the dialog
        if (dialogProps.open && !isLoadingExecution && !isLoadingApprovals) {
            if (approvals.length <= 0) {
                handleClose()
            } else {
                const _actionableTypes = actionable?.type
                    ? [actionable.type]
                    : (actionableTypes ? actionableTypes : [])

                const documents = filterApprovals(
                    approvals.filter(a => allowedStatusesToUpload.includes(a?.status || '')),
                    _actionableTypes,
                    actionable?.id
                )

                if (documents.length <= 0) {
                    handleClose()
                }
            }
        }
    }, [isLoadingExecution, isLoadingApprovals])

    function handleSubmit() {
        setShowDocumentsFlow(true)
    }

    function handleClose(fetchSwitchableEvaluators?: boolean) {
        dialogProps.onClose()

        if (fetchSwitchableEvaluators && applicationId) {
            dispatch(applicationEvaluatorFieldsActions.getSwitchableEvaluators(applicationId))
        }

        setShowDocumentsFlow(false)
        setCurrentDocumentIndex(0)
        setCurrentFieldIndex(0)
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            disableEscapeKeyDown={disableDialogClose}
            // disableBackdropClick={disableDialogClose}
            dialogTitleProps={{
                style: { paddingTop: 17, paddingBottom: 1 }
            }}
            dialogContentProps={{
                style: { paddingBottom: 32 }
            }}
            closeButtonProps={{
                className: classes.headerButton,
                style: { visibility: disableDialogClose ? 'hidden' : 'visible' }
            }}
            backAction={isLoadingExecution || currentIndex <= 0 ? <></> : (
                <IconButton
                    onClick={() => setCurrentIndex(currentIndex - 1)}
                    className={classes.headerButton}
                >
                    <ArrowLeftIcon />
                </IconButton>
            )}
            {...dialogProps}
            onClose={() => {
                // If current document index is > 0 means that there a document uploaded,
                // then, we fetch documents again
                handleClose(showDocumentsFlow && currentDocumentIndex > 0)
            }}
        >
            <Loading
                variant={isLoadingExecution ? 'circular' : 'skeleton'}
                text={'Guardando y generando documentos...'}
                skeletonsNumber={2}
                isLoading={isLoadingExecution || isLoadingActionable}
                {...isLoadingExecution && ({
                    style: {
                        height: 'auto',
                        minHeight: '40vh'
                    }
                })}
                {...loadingProps}
            >
                {showDocumentsFlow ? (
                    <DocumentsFlow
                        actionable={{
                            id: actionable?.id,
                            types: actionableTypes
                        }}
                        currentIndex={currentDocumentIndex}
                        setCurrentIndex={setCurrentDocumentIndex}
                        handleDocumentsEnd={() => {
                            handleClose(true)
                        }}
                    />
                ) : (
                    <>

                        {actionable?.type === 'Employment' && (
                            <EmploymentFormFlow
                                isNew={!Boolean(actionable?.id)}
                                handleSubmit={handleSubmit}
                                formFlowProps={{
                                    currentIndex: currentFieldIndex,
                                    setCurrentIndex: setCurrentFieldIndex,
                                }}
                            />
                        )}

                    </>
                )}
            </Loading>
        </Dialog>
    )
}