import { useSelector } from "react-redux"
import { DocumentsSection, SectionCardProps } from "../../components"
import HomeIcon from "src/assets/img/home/documents/home.svg"
import { filterApprovals } from "../../components/DocumentsFlow/helpers"
import { DocumentSectionProps } from ".."

export function PropertyDocumentsSection({ applicationId, openDocumentsDialog, setDocumentsDialog }: DocumentSectionProps) {
    const approvals = useSelector(state => state.applications.switchableEvaluators?.approvals || [])

    const propertiesIds = approvals.filter((doc, index, array) =>
        doc?.actionable_type === 'Property' &&
        array.findIndex(d => d?.actionable_id === doc?.actionable_id) === index
    ).map(doc => doc?.actionable_id || '')

    const propertiesCardsItems: SectionCardProps[] = propertiesIds.map((id, index) => ({
        actionableId: id,
        actionableType: 'Property',
        title: `Documentos Inmueble`,
        actionTitle: 'Cargar documentos',
        Icon: HomeIcon,
        approvals: filterApprovals(approvals, ['Property'], id),
        handleAction: () => openDocumentsDialog({
            actionableId: id,
            actionableTypes: ['Property'],
            showOnlyDocuments: true
        })
    }))

    return (
        <DocumentsSection
            title='Inmueble'
            hideAddButtonOnEmptyCards
            items={propertiesCardsItems}
        />
    )
}