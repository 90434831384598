import { tss } from "tss-react/mui";

export const useStyles = tss
    .withNestedSelectors<"workflowIcon" | "arrowIcon">()
    .create(({ theme, classes }) => ({
        container: {
            height: '100%',
            display: 'flex',
            position: 'relative',
            overflowY: 'hidden',
            // justifyContent: 'center',
            // width: '100%'
            [theme.breakpoints.down('sm')]: {
                height: 'calc(100vh - 50px)'
            }
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            // marginTop: 77,
            marginTop: -70,
            maxWidth: 430,
            '& h3': {
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                fontSize: 34,
                maxWidth: 338,
                marginBottom: 16
            },
            '& p': {
                fontSize: 16,
                fontWeight: 500,
                maxWidth: 430,
                marginBottom: 26
            },
            '& h6': {
                color: theme.palette.primary.main,
                fontSize: 16,
                fontWeight: 'bold',
            }
        },
        optionsContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            padding: '0px 37px 0px 36px',
            // height: 'fit-content',
            maxHeight: '100%',
            overflowY: 'auto',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 16px',
            }
        },
        headerContainer: {
            // padding: '77px 20px 6px',
            padding: '0px 20px 0px 36px',
            '& > h1': {
                fontWeight: 'bold',
                fontSize: 34,
                lineHeight: '36px',
                marginBottom: '50px',
            },
            '& > p': {
                fontSize: 20,
                fontWeight: 500,
                marginBottom: 24,
                color: '#242C37'
            },
            [theme.breakpoints.down("sm")]: {
                padding: '39px 16px 0px',
                paddingTop: '0px !important',
                '& > h1': {
                    fontSize: 24,
                    lineHeight: '28px',
                    marginTop: '24px',
                    marginBottom: '16px',
                },
                '& > p': {
                    textAlign: 'center'
                }
            }
        },
        center: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        },
        credit: {
            position: 'relative',
            overflow: 'hidden',
            borderRadius: 8,
            boxShadow: '0px 5px 22px rgba(57, 74, 97, 0.12)',
            border: '1px solid transparent',
            height: 80,
            padding: '16px 28px',
            width: '100%',
            margin: '16px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& > div': {
                display: 'flex',
                alignItems: 'center',
            },
            '& p': {
                color: '#242C37',
                fontWeight: 500,
                fontSize: 18,
                margin: '0px 0px 0px 16px'
            },
            '&:hover': {
                borderColor: theme.palette.primary.main,
                cursor: 'pointer',
                [`.${classes.arrowIcon}`]: {
                    opacity: 1,
                    right: 28
                },
                [`.${classes.workflowIcon} path`]: {
                    fill: theme.palette.primary.main
                }
            },
            '&:active': {
                boxShadow: 'none'
            },
            [theme.breakpoints.down('sm')]: {
                "& p": {
                    fontSize: '110%',
                },
            },
            ["@media (max-width: 320px)"]: {
                "&:hover p": {
                    fontSize: '100%',
                }
            }
        },
        arrowIcon: {
            fill: theme.palette.primary.main,
            opacity: 0,
            transition: '0.2s',
            position: 'absolute',
            right: -28
        },
        workflowIcon: {
            width: 36,
            height: 36,
            display: 'block!important',
            '& path': {
                fill: '#CFD5E2',
                transition: '0.2s',
            }
        },
        backgroundGray: {
            background: '#fafafc',
            position: 'absolute',
            width: '41.66%',
            height: '100%',
            top: 0,
            left: 0,
            ['@media (max-width: 1064px)']: {
                background: 'none',
                display: 'none'
            }
        },
        helper: {
            display: 'flex',
            maxWidth: 240,
            flexDirection: 'column',
            borderLeft: '1px solid ' + theme.palette.primary.main,
            paddingLeft: 16,
            position: 'absolute',
            left: 32
        },
        helperDivider: {
            height: '100%',
            background: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
            margin: '0px 4px'
        },
        helperTitle: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: 16,
            marginBottom: 16,
        },
        helperDescription: {
            color: '#242C37',
            fontSize: 16,
            fontWeight: 400,
            marginBottom: 8,
        },
        helperHint: {
            color: theme.palette.primary.main,
            fontSize: 12,
            fontWeight: 400,
        },
        imgContainer: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: theme.spacing(3, 4)
        },
        dataContainer: {
            maxHeight: '100%',
            // height: 'fit-content',
            overflowY: 'auto',
            ['@media (max-width: 1064px)']: {
                flexBasis: '100%',
                maxWidth: '100%',
                flexGrow: 0
            }
        },
        loadingContainer: {
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                height: 'calc(100vh - 50px)'
            }
        },
        heroContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            justifySelf: 'stretch',
            position: 'relative',
            ['@media (max-width: 1064px)']: {
                display: 'none'
            },
            '& > svg': {
                width: '100%',
                height: '100%'
            }
        },
        heroImage: {
            width: '100%',
            height: '100%'
        }
    }));