import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    itemActions: {
        gap: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between'
        },
        '& button': {
            [theme.breakpoints.down('sm')]: {
                padding: '8px 4px',
            }
        }
    },
    imageAndText: {
        display: 'flex',
        gap: '16px',
    },
    data: {
        display: 'flex',
        flexDirection: 'column',
        '& > h3': {
            fontSize: 22,
            color: '#242C37',
            fontWeight: 'bold',
        },
        [theme.breakpoints.down('sm')]: {
            '& > p': {
                paddingTop: 8
            }
        }
    },
    contactData: {
        display: 'flex',
        gap: 50,
        flexWrap: 'wrap',
        '& > p': {
            display: 'flex',
            alignItems: 'center'
        },
        '& svg': {
            height: 14,
            fill: theme.palette.primary.main
        },
        [theme.breakpoints.down('sm')]: {
            gap: 8,
            flexDirection: 'row',
            '& > p': {
                justifyContent: 'center'
            }
        },
        [theme.breakpoints.down('sm')]: {
            padding: '30px 0',
        }
    },
    breadcrumbs: {
        padding: '60px 16px 0',
        zIndex: 1,
    },
    avatar: {
        height: 90,
        width: 90,
        backgroundColor: theme.palette.primary.light,
        [theme.breakpoints.down('sm')]: {
            height: 60,
            width: 60,
        }
    },
    dataContainer: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'left'
    },
    skeleton: {
        maxWidth: '100%'
    }
}));
