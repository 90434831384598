import { useState, MouseEvent } from "react"
import { useLocation } from "react-router-dom"
import { AppBar, Toolbar, Button, Hidden, Avatar, Typography, Box, Divider, IconButton } from "@mui/material"
import { AccountCircleOutlined as PersonIcon, Menu as MenuIcon } from "@mui/icons-material"
import { LogoutIcon } from "src/assets/icons"

import { useDispatch, useSelector } from "react-redux"
import { authActions } from "src/store/auth/actions"

import { cookies, getCookieName, getFullName, getImageSource, initialsFor, segment } from "src/helpers"

import { Link } from "../../components"
import { TopbarMenu } from "./TopbarMenu"
import { useStyles } from "./Topbar.styles"

export function Topbar() {
	const dispatch = useDispatch()
	const location = useLocation()

	const { website, logo, theme } = useSelector(state => state.organization)
	const { isAuthenticated, customer } = useSelector(state => state.auth)

	const { classes, cx } = useStyles(theme)()
	const [openSide, setOpenSide] = useState<boolean>(false)

	const logout = () => dispatch(authActions.logout())
	const isNotAuthenticated = !Boolean(cookies.get(getCookieName()))
	const hideLoginButtons = location.pathname === "/maintenance"

	function handleLogout() {
		logout()
		segment.track('Customer', 'Logout')
		setOpenSide(false)
	}

	return (
		<AppBar position="fixed" elevation={0} className={classes.appbar}>
			<Hidden smDown>
				<Toolbar className={cx(classes.toolbar, { [classes.center]: hideLoginButtons })}>

					<Link
						native={isNotAuthenticated}
						to={isNotAuthenticated && website || "/"}
					>
						<img
							style={{
								width: theme?.navbar?.logo ? 'auto' : '105px',
								maxWidth: 193,
								aspectRatio: theme?.navbar?.logo ? 'auto' : 4,
							}}
							src={theme?.navbar?.logo || logo || getImageSource()}
							alt="Logo"
						/>
					</Link>

					{!hideLoginButtons && (
						<div className={classes.navItems}>
							{(isAuthenticated && Boolean(cookies.get(getCookieName()))) ? (
								<>
									<Box display='flex' justifyContent='center' alignItems='center'>

										<Avatar className={classes.avatar}>
											<Typography className={classes.avatarText}>
												{initialsFor(getFullName(customer)) || '🙂'}
											</Typography>
										</Avatar>

										<Typography className={classes.customerName}>
											{`${customer?.first_name || ''} ${customer?.last_name || ''}`}
										</Typography>

										<Divider orientation="vertical" className={classes.divider} />

										<IconButton onClick={handleLogout}>
											<LogoutIcon /*style={{ verticalAlign: 'middle' }}*/ />
										</IconButton>

									</Box>

								</>
							) : (
								<>
									<Link to={location.pathname === "/magic_link" ? "/create_account" : "/magic_link"}>
										<Button startIcon={<PersonIcon color="primary" />} >
											{location.pathname === "/magic_link" ? "Crear una cuenta" : "Iniciar sesión"}
										</Button>
									</Link>

									{(isNotAuthenticated && location.pathname != "/") && (
										<Link to="/">
											<Button
												variant="contained"
												color="primary"
												style={{ marginLeft: 24 }}
											>
												Aplicar
											</Button>
										</Link>
									)}
								</>
							)}
						</div>
					)}

				</Toolbar>
			</Hidden>

			<Hidden smUp>
				<Toolbar className={classes.toolbar}>
					{!hideLoginButtons && (
						<MenuIcon
							color="primary"
							onClick={() => setOpenSide(true)}
							className={classes.menuIconResponsive}
						/>
					)}

					<Link
						native={isNotAuthenticated}
						to={isNotAuthenticated && website || "/"}
					>
						<img
							style={{ width: '72px', aspectRatio: '4' }}
							src={logo}
							alt="Logo"
						/>
					</Link>

					<TopbarMenu
						open={openSide}
						onClose={() => { setOpenSide(false) }}
						isAuthenticated={isAuthenticated}
						cookies={cookies}
						logout={handleLogout}
					/>

				</Toolbar>
			</Hidden>

		</AppBar>
	)
}