import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 24
    },
    button: {
        padding: '10px 16px',
    },
    loadingContainer: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 50px)',
        }

    },
    form: {
        width: '100%',
    },
    subContainer: {
        padding: '0px 16px',
        "& svg": {
            marginLeft: '10%'
        },
        [theme.breakpoints.down('sm')]: {
            "& svg": {
                margin: 'auto',
                marginLeft: '36%'
            }
        },
    },
    dividerContainer: {
        margin: '16px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > p': {
                color: 'rgba(0, 0, 0, 0.23)'
            },
            '& > hr': {
                width: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.23)'
            },
        },
        [theme.breakpoints.down('sm')]: {
            margin: '16px 0',
        }
    }
}));