import { call, delay, fork, put, takeLatest } from "redux-saga/effects"
import { sagaWrapper } from "src/helpers"
import { ApplicationService, Service } from "src/services"
import { Action, ActionableType, CustomSwitchableEvaluator, Response } from "src/types"
import { snackbarActions } from "src/store/snackbar/actions"
import { APPLICATION_EVALUATOR_FIELDS } from "./types"
import { applicationEvaluatorFieldsActions } from "./actions"
import { applicationRejectedDocuments } from "../actions"
import _ from "lodash"

const employmentService = new Service('employments')
const applicationService = new Service('application_workflows')

function* getActionable({ payload }: Action) {
    const { id, type } = payload

    let response: Response = {
        data: {},
        meta: {}
    }

    switch (type as ActionableType) {

        default: break

        case 'Employment': {
            response = yield call(employmentService.findOne, id)
            break
        }
    }

    yield put(applicationEvaluatorFieldsActions.setActionable({
        actionable: response?.data,
        actionableId: id || response?.data?.id,
        actionableType: type
    }))
}

function* executeEvaluators({ payload, type }: Action) {
    const { applicationId, actionable, actionableType, processType } = payload

    let response: Response = {
        data: {},
        meta: {}
    }

    switch (actionableType as ActionableType) {

        default: break

        case 'Employment': {

            if (processType === 'delete') {
                yield call(employmentService.destroy, actionable?.id)
            } else {
                const employment = {
                    ...actionable,
                    application_workflow_customer_resources_attributes: [
                        { application_workflow_id: applicationId }
                    ]
                }

                if (processType === 'create') {
                    response = yield call(employmentService.create, { employment })
                } else {
                    response = yield call(employmentService.update, actionable.id, { employment })
                }
            }

            break
        }
    }

    // call(ApplicationService.patchPath, `${applicationId}/resync`, {})
    yield fork(resync, { payload, type })
    yield delay(13000) // there is a delay job in back-end

    const rejectedDocumentsresponse: Response = yield call(ApplicationService.allPath, `${applicationId}/documents`, {
        search: {
            'status_not_in': ['error', 'not_applicable', 'mannual_not_applicable', 'running']
        }
    })

    yield put(applicationRejectedDocuments.setAll(rejectedDocumentsresponse.data))

    if (processType === 'delete') {
        yield put(applicationEvaluatorFieldsActions.setActionable({}))
        yield put(snackbarActions.add('success', 'Se ha borrado con éxito'))
    } else {
        yield put(applicationEvaluatorFieldsActions.setActionable({
            actionable: response?.data,
            actionableId: response?.data?.id,
            actionableType
        }))

        yield put(snackbarActions.add('success', 'Se ha guardado con éxito'))
    }
}

function* resync({ payload }: Action) {
    const { applicationId } = payload
    yield call(ApplicationService.patchPath, `${applicationId}/resync`, {})
}

function* getSwitchableEvaluators({ payload }: Action) {
    const response: CustomSwitchableEvaluator[] = yield call(
        applicationService.allPath,
        `${payload.applicationId}/switchable_evaluator_fields`, {
        page: 1,
        perPage: 999,
    }, false)
    yield put(applicationEvaluatorFieldsActions.setSwitchableEvaluators(response))
}

export function* applicationEvaluatorFieldsSagas() {
    yield takeLatest(APPLICATION_EVALUATOR_FIELDS.GET_ACTIONABLE, sagaWrapper(getActionable))
    yield takeLatest(APPLICATION_EVALUATOR_FIELDS.EXECUTE_EVALUATORS, sagaWrapper(executeEvaluators))
    yield takeLatest(APPLICATION_EVALUATOR_FIELDS.GET_SWITCHABLE_EVALUATORS, sagaWrapper(getSwitchableEvaluators))
}