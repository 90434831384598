import { useState } from "react"
import { Button, CircularProgress, Grid, Typography } from "@mui/material"
import { ArrowForwardIos as ArrowRightIcon } from "@mui/icons-material"
import { EmptyState } from "src/components"
import { DocumentRejectedComments } from "./RejectedComments"
import { UploadComponent } from "../InputComponent"

import { useDispatch, useSelector } from "react-redux"
import { snackbarActions } from "src/store/snackbar/actions"

import { ApplicationService } from "src/services"
import { ActionableType, Response } from "src/types"
import { allowedStatusesToUpload, filterApprovals, getRejectComments } from "./helpers"
import { useStyles } from "./styles"
import _ from "lodash"

export interface DocumentsFlowProps {
    actionable?: {
        /** Use to group attachments by actionable id */
        id?: string,

        /** Use to filter attachments by types */
        types?: ActionableType[]
    }

    /** Current document from array store rejected documents, by default is 0 */
    currentIndex?: number

    /** State callback to set the current index */
    setCurrentIndex?: (index: number) => void

    /** Callback to invoke after upload the last document (final continue button) */
    handleDocumentsEnd?: () => void
}

/** Component to render a each to each a document upload from rejected documents store */
export function DocumentsFlow({ actionable = {}, currentIndex = 0, setCurrentIndex, handleDocumentsEnd }: DocumentsFlowProps) {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const { id: actionableId, types: actionableTypes = [] } = actionable

    const customer = useSelector(state => state.auth.customer)
    const user = useSelector(state => state.auth.customer.user)
    const organization = useSelector(state => state.organization)
    const application = useSelector(state => state.applications.application)
    const approvals = useSelector(state => state.applications.switchableEvaluators.approvals || [])

    const [isUploading, setIsUploading] = useState(false)

    const _approvals = filterApprovals(
        approvals.filter(a => allowedStatusesToUpload.includes(a?.status || '')),
        actionableTypes,
        actionableId
    )

    const currentApproval = _approvals[currentIndex]

    const rejectedComments = currentIndex >= 0 ? getRejectComments(
        currentApproval?.resource_criteria,
        currentApproval?.verification_reason,
        currentApproval?.comments
    ) : []

    async function handleUpload(attachment?: FormData, id?: string) {
        try {
            setIsUploading(true)
            let response: Response

            if (currentApproval?.approval_type === 'dynamic') {
                attachment?.append('answer[key]', currentApproval?.field?.key || '')
                attachment?.append('answer[field_type]', currentApproval?.field?.field_type || '')
                attachment?.append('verification_id', currentApproval?.verification_id || '')

                attachment?.append(
                    'additional_field_key',
                    // @ts-ignore
                    `answers.additional_fields.${currentApproval?.field?.key || currentApproval?.key}.value`
                )

                if (currentApproval?.actionable_type === currentApproval?.field?.config?.associated_resource) {
                    attachment?.append('associated_resource_id', currentApproval?.actionable_id || '')
                }

                const locations = currentApproval?.field?.config?.locations || []

                if (_.isArray(locations) && locations.length > 0) {
                    locations.forEach((_location: string) => {
                        attachment?.append('locations[]', _location)
                    })
                }
                response = await ApplicationService.patchPath(`${application.id}/answers/manage`, attachment)
            } else {
                response = await ApplicationService.patchPath(`${application.id}/answers/${id}/manage`, attachment)

                // @ts-ignore
                const answerId = currentApproval?.answer_id || currentApproval?.answer?.id

                await ApplicationService.patchPath(`${application.id}/rejected_documents/${answerId}`, {
                    answer: {
                        value: response.data?.value,
                        verification_id: currentApproval?.verification_id,
                        field_id: currentApproval?.field?.id,
                        key: currentApproval?.field?.key,
                        field_type: currentApproval?.field?.field_type
                    }
                })

                // TODO: Verify why this do use in back-end and not here
                // await ApplicationService.patchPath(`${application.id}/documents/${currentApproval?.id}`, {
                //     answer: {
                //         // @ts-ignore
                //         id: currentApproval?.answer_id || currentApproval?.answer?.id,
                //         value: response.data?.value,
                //         verification_id: currentApproval?.verification_id,
                //         field_id: currentApproval?.field?.id,
                //         key: currentApproval?.field?.key,
                //         field_type: currentApproval?.field?.field_type
                //     }
                // })
            }

            setIsUploading(false)
            dispatch(snackbarActions.add('success', 'Documento enviado'))
            next()
        } catch {
            setIsUploading(false)
            dispatch(snackbarActions.add('error', 'Lo sentimos, ha ocurrido un error'))
        }
    }

    /** If current approval is the last one, close the dialog, else, increase the current index */
    function next() {
        if (currentIndex === (_approvals.length - 1)) {
            handleLastDocument()
        } else if (_.isFunction(setCurrentIndex)) {
            setCurrentIndex(currentIndex + 1)
        }
    }

    function handleLastDocument() {
        if (_.isFunction(handleDocumentsEnd)) {
            handleDocumentsEnd()
        }
    }

    return _approvals.length <= 0 || currentIndex < 0 ? (
        <EmptyState
            size="medium"
            text="No hay documentos pendientes y/o rechazados"
            containerProps={{
                style: { margin: 0 }
            }}
        />
    ) : (
        <Grid container spacing={3}>
            <Grid
                item
                xs={12}
                style={{ textAlign: 'center' }}
            >
                <Typography className={classes.title}>
                    {currentApproval?.field?.label || 'Campo'}
                </Typography>
            </Grid>

            {!_.isEmpty(rejectedComments) && (
                <Grid item xs={12}>
                    <DocumentRejectedComments rejectedComments={rejectedComments} />
                </Grid>
            )}

            <Grid item xs={12}>
                <UploadComponent
                    type={currentApproval?.field?.field_type || ''}
                    key={currentApproval?.id}
                    fieldKey={currentApproval?.field?.key || ''}
                    approval={currentApproval}
                    dispatch={dispatch}
                    approvalId={currentApproval.id}
                    actionableId={currentApproval?.actionable_id || ''}
                    actionableType={currentApproval?.actionable_type as ActionableType}
                    user={user}
                    customer={customer}
                    application={application}
                    organization={organization}
                    handleClose={next}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    applicationService={ApplicationService}
                    actionable={{
                        id: currentApproval?.actionable_id || '',
                        type: currentApproval?.actionable_type as ActionableType
                    }}
                    onUpload={(attachment?: FormData, application?: any, id?: string) => {
                        if (attachment) {
                            handleUpload(attachment, id)
                        }
                    }}
                />
            </Grid>

            <Grid
                item
                xs={12}
                style={{ textAlign: 'right' }}
            >
                <Button
                    variant={isUploading ? 'outlined' : 'contained'}
                    color="primary"
                    size="medium"
                    endIcon={currentIndex > _approvals.length - 1
                        ? <ArrowRightIcon style={{ width: 16 }} />
                        : <></>}
                    disabled={isUploading}
                    onClick={() => {
                        if (_.isFunction(setCurrentIndex) && currentIndex < _approvals.length - 1) {
                            setCurrentIndex(currentIndex + 1)
                        } else {
                            handleLastDocument()
                        }
                    }}
                >
                    {isUploading ? (
                        <CircularProgress size={24} variant="indeterminate" />
                    ) : (
                        currentIndex < _approvals.length - 1
                            ? 'Continuar'
                            : 'Volver a documentos'
                    )}
                </Button>
            </Grid>
        </Grid>
    )
}