import { tss } from "tss-react/mui";

export const usetStyles = tss.create(({ theme }) => ({
	root: {
		width: '100% !important',
		marginTop: '64px',
		padding: '0 30px',
		paddingBottom: '60px',
		maxWidth: 1440,
		[theme.breakpoints.between('sm', 'md')]: {
			height: '100%'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 0px',
			paddingBottom: '60px',
			marginTop: '80px',
		}
	},
	headerContainer: {
		width: '100%',
		padding: '0',
		borderRadius: 8,
		marginBottom: 50,
		'& > h1': {
			fontWeight: 'bold',
			fontSize: 34,
			color: '#242C37',
			lineHeight: '36px',
			marginBottom: 16,
		},
		'& > h6': {
			fontSize: 20,
			color: '#242C37',
			lineHeight: '24px',
			// letterSpacing: '0.5px'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '24px 16px 19px',
			marginBottom: 0,
			'& > h1': {
				fontWeight: 'bold',
				fontSize: 24,
				color: '#242C37',
				lineHeight: '24px',
				marginBottom: '8px',
				textAlign: 'center',
			},
			'& > h6': {
				fontSize: 16,
				fontWeight: 'normal',
				padding: '0 16px',
			},

		}
	},
	summaryContainer: {
		width: '100%',
		padding: '8px 34px 12px',
		border: '1px solid #CFD5E2',
		borderRadius: 8,
		background: ' rgba(117, 139, 183, 0.04)',
		marginBottom: 24,
		'& > div': {
			width: '100%',
			display: 'flex',
			marginBottom: 0,
			alignItems: 'center',
			justifyContent: 'space-between',
			'& > h2': {
				fontSize: 20,
				// letterSpacing: '0.18px',
				color: '#242C37',
				fontWeight: 'bold'
			},
			'& > h3': {
				fontSize: 20,
				// letterSpacing: '0.15px',
				color: theme.palette.primary.main,
				fontWeight: 'bold',
				display: 'flex',
				alignItems: 'center',
				'& svg': {
					marginLeft: 8
				}
			}
		},
		[theme.breakpoints.down('sm')]: {
			'& > div': {
				marginBottom: 8,
			},
			borderRadius: 0,
			border: 'none',
			borderBottom: '1px solid #CFD5E2',
			borderTop: '1px solid #CFD5E2',
			padding: '8px 16px 0px',

		}
	},
	summaryData: {
		display: 'flex',
		flexDirection: 'column',
		'& > div': {
			width: '100%',
			padding: '16px 18px 16px 18px',
			background: '#fff',
			border: '1px solid #CFD5E2',
			borderRadius: 8,
			margin: '16px 0px 12px',
			[theme.breakpoints.down('sm')]: {
				margin: '8px 0px',
			}
		},
	},
	stageTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 8,
		marginBottom: 16,
		'& h4': {
			fontSize: 20,
			// letterSpacing: '0.15px',
			fontWeight: 'bold',
			color: theme.palette.primary.dark,
			[theme.breakpoints.down('sm')]: {
				fontSize: 20
			}
		},
		'& p': {
			display: 'flex',
			alignItems: 'center',
			border: '1px solid',
			borderColor: theme.palette.primary.main,
			borderRadius: 24,
			padding: '4px 8px',
			color: theme.palette.primary.main,
			letterSpacing: '0.4px',
			fontSize: 12,
			fontWeight: 500,
			margin: 0,
			'& svg': {
				marginLeft: 6,
				height: 16,
				width: 16
			}
		}
	},
	values: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 16
	},
	input: {
		fontWeight: 'bold',
		color: '#242C37',
		fontSize: 16,
	},
	value: {
		fontSize: 16,
		// letterSpacing: '0.5px',
		color: '#8892A0',
		marginTop: 4
	},
	titleContainer: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
	},
	chipContainer: {
		display: 'flex',
		height: '100%',
		alignItems: 'flex-start',
		marginLeft: 16,
		"&:hover": {
			cursor: 'pointer'
		}
	},
	buttonContainer: {
		width: '100%',
		maxWidth: 1440,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '32px 0px',
		[theme.breakpoints.down('md')]: {
			padding: '32px 16px',
			flexDirection: 'column',
			gap: 8
		}
	},
	button: {
		padding: '16px 10px',
		width: 171,
		[theme.breakpoints.down('sm')]: {
			width: 164,
			padding: '16px 10px',
		},
	},
	legend: {
		fontSize: 14,
		fontWeight: 'bold',
		marginTop: 12,
		letterSpacing: 0
	},
	termsConditionsContainer: {
		marginBottom: 0,
	},
	termCondition: {
	},
	cancelButton: {
		[theme.breakpoints.down('md')]: {
			order: 2,
			border: 'none',
			width: '100%',
			'& svg': {
				display: 'none'
			}
		},
	},
	submitButton: {
		[theme.breakpoints.down('md')]: {
			order: 1,
			width: '100%',
		},
	}
}));