import {
    Action,
    ActionableType,
    Application,
    ApplicationCategoryType,
    ApplicationScoreType,
    Attachment,
    AttachmentParamsType,
    CustomSwitchableEvaluator,
    CustomSwitchableEvaluatorQualifier,
    CustomSwitchableEvaluatorQualifierField,
    MetaDataType,
    RejectedDocumentType
} from "src/types";
import { QUESTION_TYPES } from "../questions/types";
import { SECTION_TYPES } from "../sections/types";
import { APPLICATION_CATEGORIES_TYPES } from "./categories/types";
import { APPLICATION_REJECTED_DOCUMENTS } from "./rejectedDocuments/types";
import { APPLICATION_TYPES } from "./types";
import { APPLICATION_EVALUATOR_FIELDS } from "./evaluatorFields/types";
import _ from "lodash"

export interface ApplicationStateType {
    application: Application
    data: Application[]
    lastApplication?: Application
    isLoading: boolean
    isUpdating: boolean
    isPublishing: boolean
    isLoadingScoreResult: boolean,
    scoreResult?: ApplicationScoreType
    applicationPusherChannels: string[],
    showSummary?: boolean
    summaryAlreadyShown?: boolean
    rejectedDocuments: {
        hasAny: boolean
        data?: RejectedDocumentType[]
        isLoading: boolean
    }
    categories: {
        data?: ApplicationCategoryType[]
        isLoading: boolean
    }
    evaluatorFields: {
        data?: any[]
        isLoading: boolean,
        isLoadingExecution: boolean,
        isLoadingActionable: boolean,
        actionable?: {
            data?: any
            id?: string,
            type?: ActionableType
        }
    },
    switchableEvaluators: {
        isLoading: boolean
        data?: CustomSwitchableEvaluator[]
        qualifiers?: CustomSwitchableEvaluatorQualifier[]
        approvals?: CustomSwitchableEvaluatorQualifierField[]
    }
}

const INITIAL_STATE: ApplicationStateType = {
    application: {},
    data: [],
    isLoading: false,
    isUpdating: false,
    isPublishing: false,
    applicationPusherChannels: [],
    isLoadingScoreResult: false,
    rejectedDocuments: {
        hasAny: false,
        isLoading: false
    },
    categories: {
        isLoading: false
    },
    evaluatorFields: {
        data: [],
        isLoading: false,
        isLoadingExecution: false,
        isLoadingActionable: false
    },
    switchableEvaluators: {
        isLoading: false,
        data: []
    }
}

export function applicationReducer(state: ApplicationStateType = INITIAL_STATE, action: Action): ApplicationStateType {
    const { type, payload } = action;
    switch (type) {

        case APPLICATION_TYPES.SET: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }

        case APPLICATION_TYPES.CREATE_APPLICATION_BY_ROOT_SLUG:
        case APPLICATION_TYPES.CONTINUE_APPLICATION:
        case APPLICATION_TYPES.GET_APPLICATION:
        case APPLICATION_TYPES.CREATE_APPLICATION:
        case APPLICATION_TYPES.GET_APPLICATIONS: {
            return {
                ...state,
                isLoading: true
            }
        }

        case APPLICATION_TYPES.GET_LASTONE_APPLICATION: {
            return {
                ...state,
                isLoading: true
            }
        }

        case APPLICATION_TYPES.SET_APPLICATIONS: {
            return {
                ...state,
                isLoading: false,
                data: payload.applications
            }
        }

        case APPLICATION_TYPES.SET_APPLICATION: {
            return {
                ...state,
                isLoading: false,
                isUpdating: false,
                application: payload.application,
            }
        }

        case SECTION_TYPES.SET_CURRENT_SECTION:
        case QUESTION_TYPES.SAVE_ANSWERS: {
            return {
                ...state,
                isUpdating: true
            }
        }

        case APPLICATION_TYPES.SET_LASTONE_APPLICATION: {
            return {
                ...state,
                isLoading: false,
                lastApplication: payload.application
            }
        }

        case APPLICATION_TYPES.HANDLE_GET_ERROR: {
            return {
                ...state,
                isLoading: false,
                isUpdating: false
            }
        }

        case APPLICATION_TYPES.ADD_APPLICATION_PUSHER_CHANNEL: {
            return {
                ...state,
                applicationPusherChannels: [
                    ...state.applicationPusherChannels, payload.channel
                ]
            }
        }

        case APPLICATION_TYPES.PUBLISH: {
            return {
                ...state,
                isPublishing: true,
                showSummary: false,
                summaryAlreadyShown: false
            }
        }

        case APPLICATION_TYPES.GET_SCORE_RESULT: {
            return {
                ...state,
                isLoadingScoreResult: true
            }
        }

        case APPLICATION_TYPES.SET_SCORE_RESULT: {
            return {
                ...state,
                scoreResult: payload.score,
                isLoadingScoreResult: false
            }
        }

        case APPLICATION_REJECTED_DOCUMENTS.GET_ALL_DELAYED:
        case APPLICATION_REJECTED_DOCUMENTS.GET_ALL: {
            return {
                ...state,
                rejectedDocuments: {
                    ...state.rejectedDocuments,
                    isLoading: true
                }
            }
        }

        case APPLICATION_REJECTED_DOCUMENTS.SET_ALL: {
            return {
                ...state,
                rejectedDocuments: {
                    ...state.rejectedDocuments,
                    isLoading: false,
                    data: payload.documents,
                }
            }
        }

        case APPLICATION_CATEGORIES_TYPES.GET_ALL: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isLoading: true
                }
            }
        }

        case APPLICATION_CATEGORIES_TYPES.SET_ALL: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isLoading: false,
                    data: payload.categories
                }
            }
        }

        case APPLICATION_REJECTED_DOCUMENTS.SET_HAS_ANY: {
            return {
                ...state,
                rejectedDocuments: {
                    ...state.rejectedDocuments,
                    hasAny: Boolean(payload.value)
                }
            }
        }

        case APPLICATION_EVALUATOR_FIELDS.EXECUTE_EVALUATORS: {
            return {
                ...state,
                evaluatorFields: {
                    ...state.evaluatorFields,
                    isLoadingExecution: true
                },
                rejectedDocuments: {
                    ...state.rejectedDocuments,
                    isLoading: true
                }
            }
        }

        case APPLICATION_EVALUATOR_FIELDS.GET_ACTIONABLE: {
            return {
                ...state,
                evaluatorFields: {
                    ...state.evaluatorFields,
                    isLoadingActionable: true
                }
            }
        }

        case APPLICATION_EVALUATOR_FIELDS.SET_ACTIONABLE: {
            return {
                ...state,
                evaluatorFields: {
                    ...state.evaluatorFields,
                    isLoadingExecution: false,
                    isLoadingActionable: false,
                    actionable: {
                        id: payload?.actionableId,
                        type: payload?.actionableType,
                        data: payload?.actionable
                    }
                }
            }
        }

        case APPLICATION_EVALUATOR_FIELDS.GET_SWITCHABLE_EVALUATORS: {
            return {
                ...state,
                switchableEvaluators: {
                    ...state.switchableEvaluators,
                    isLoading: true
                }
            }
        }

        case APPLICATION_EVALUATOR_FIELDS.SET_SWITCHABLE_EVALUATORS: {
            const switchableEvaluators = ((payload?.switcheableEvaluators || []) as CustomSwitchableEvaluator[])
                .filter(e => e.qualifiers
                    .some(q => q.fields
                        .some(f => f.document_required)))
                .map(q => ({
                    ...q,
                    qualifiers: _.sortBy(q.qualifiers, 'sort_order').map(q => q)
                }))

            // filter approvals with some status or if is required
            const evaluatorsApprovals = switchableEvaluators.map(({ qualifiers }) => (
                qualifiers.map(q => q.fields).flat()
            )).flat().filter(a => a.document_required || ['failed', 'input_required', 'finished'].includes(a.status))

            return {
                ...state,
                switchableEvaluators: {
                    ...state.switchableEvaluators,
                    data: switchableEvaluators,
                    isLoading: false,
                    approvals: evaluatorsApprovals,
                }
            }
        }

        default:
            return state;
    }
}