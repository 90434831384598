import { useEffect, useState } from "react"
import { Grid, Typography } from "@mui/material"
import { AutoCompleteInput, BinaryInput, SelectInput } from "@kredimx/form-builder"
import { HelpOutline as HelpIcon } from "@mui/icons-material"

import { Tooltip } from "src/components"
import { isValidId } from "src/helpers"
import { Employment, EmploymentResourceType } from "src/types"

import { FormFlow, FormFlowProps } from "../../../components"

import { useDispatch, useSelector } from "react-redux"
import { applicationEvaluatorFieldsActions } from "src/store/application/actions"

import { getEmploymentFields } from "./fields"
import { initialValues } from "./helpers"
import { useStyles } from "./styles"

import _ from "lodash"

interface EmploymentFormFlowProps {
    isNew?: boolean
    resourceType?: EmploymentResourceType
    formFlowProps?: FormFlowProps
    handleSubmit?: () => void
}

export function EmploymentFormFlow({ isNew, resourceType, formFlowProps, handleSubmit }: EmploymentFormFlowProps) {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const application = useSelector(state => state.applications.application)
    const employment = useSelector(state => state.applications.evaluatorFields?.actionable?.data) as (Employment | undefined)

    const [salaryScheme, setSalaryScheme] = useState('')
    const [active, setActive] = useState(false)
    const [predetermined, setPredetermined] = useState(false)

    const fields = getEmploymentFields({ salaryScheme, predetermined, catalogs: {}, active })

    const _initialValues = initialValues({
        fields,
        employment,
        resourceType,
        isNew: isNew || false,
        salaryScheme,
    })

    useEffect(() => {
        return () => {
            setSalaryScheme('')
            setActive(false)
            setPredetermined(false)
        }
    }, [])

    useEffect(() => {
        if (employment?.salary_scheme) {
            setSalaryScheme(employment?.salary_scheme || '')
            setActive(Boolean(employment?.active))
            setPredetermined(Boolean(employment?.predetermined))
        }
    }, [employment?.salary_scheme])

    function onSubmit(values: any) {
        const defaultPhone = employment?.phones?.find(phone => phone.default)

        const checking_method = _.isString(values?.checking_method)
            ? [values?.checking_method]
            : (_.isArray(values?.checking_method) ? values?.checking_method?.map((value: any) => value.value ? value.value : value) : [])

        const _values = {
            ...(!isNew && ({ ...employment })),
            ...values,

            checking_method,
            ends_on: values.predetermined ? '' : values.ends_on,
            net_salary: Boolean(values?.net_salary) ? values?.net_salary : 0,

            ...(resourceType === 'creditParticipant' && ({
                phones_attributes: [{
                    id: defaultPhone?.id,
                    code_area: String(values?.default_phone?.phone_country_code || '').slice(1),
                    number: values?.default_phone?.phone,
                    default: Boolean(defaultPhone) || ((employment?.phones?.length || 0) === 0),
                }]
            }))
        }

        if (isValidId(application?.id)) {
            dispatch(applicationEvaluatorFieldsActions.executeEvaluators(
                isNew ? 'create' : 'update',
                application.id!,
                'Employment',
                { ...employment, ..._values }
            ))

            if (_.isFunction(handleSubmit)) {
                handleSubmit()
            }
        }
    }

    return (
        <Grid container spacing={3}>

            <Grid
                item
                xs={12}
                style={{ textAlign: 'center' }}
            >
                <Typography className={classes.title}>
                    Información de empleo
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <FormFlow
                    {...formFlowProps}
                    formBuilderProps={{
                        fields: fields,
                        initialValues: _initialValues,
                        onSubmit: onSubmit,
                        hideCancel: true,
                        hideHelpers: true,
                        showValidationCheckIcon: false,
                        actionContainerProps: {
                            style: {
                                justifyContent: 'flex-end',
                                marginTop: 33
                            },
                        },
                        submitCaption: "Guardar cambios",
                        customInputs: {
                            salary_scheme_field: function SalarySchemeInput({ onChange, ...rest }: any) {
                                return (
                                    <SelectInput
                                        {...rest}
                                        onChange={(e: any) => {
                                            onChange(e)
                                            setSalaryScheme(e.target.value)
                                        }}
                                    />
                                )
                            },
                            custom_binary: function CustomBinaryInput({ onChange, ...rest }: any) {
                                return (
                                    <BinaryInput
                                        {...rest}
                                        onChange={(e: any) => {
                                            onChange(e)
                                            setActive(e.target.value)
                                        }}
                                    />
                                )
                            },
                            predetermined_binary: function CustomBinaryInput({ label, ...rest }: any) {
                                return (
                                    <BinaryInput
                                        {...rest}
                                        label={(
                                            <Grid container className={classes.predeterminedLabel}>
                                                <Typography> {label} </Typography>
                                                <Tooltip title="Empleo que se prellenará en las solicitudes que haga el cliente">
                                                    <span className={classes.helIconContainer}><HelpIcon color="primary" className={classes.helpIcon} /> </span>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    />
                                )
                            },
                            autocomplete: function customAutocompleteInput(props: any) {
                                return (
                                    <AutoCompleteInput
                                        {...props}
                                        multiple={props?.config?.multiple || false}
                                        labelAttribute="caption"
                                        valueAttribute="value"
                                    />
                                )
                            },
                            autocomplete2: function AutocompleteInput2(props: any) {
                                const value = props?.options?.find((o: any) => o.value === props?.value)
                                return (
                                    <AutoCompleteInput
                                        {...props}
                                        multiple={props?.config?.multiple || false}
                                        labelAttribute="caption"
                                        valueAttribute="value"
                                        value={{ ...value }}
                                    />
                                )
                            }
                        }
                    }}
                />
            </Grid>

        </Grid>
    )
}