import { useState } from "react";
import { FormBuilder, FormBuilderProps } from "src/components";
import { ArrowForwardIos as ArrowRightIcon } from "@mui/icons-material"
import { Field } from "src/types";
import { getValidationSchema } from "@kredimx/form-builder";
import _ from "lodash"

export interface FormFlowProps {
    /** If is true show all fields instead of each */
    showAll?: boolean

    /** Use to customize the form builder */
    formBuilderProps?: FormBuilderProps

    /** Current field from array fields, by default is 0 */
    currentIndex?: number

    /** State callback to set the current index */
    setCurrentIndex?: (index: number) => void

    isUpdating?: boolean
}


/** Component to render a each to each fields from a form */
export function FormFlow({ showAll, formBuilderProps, currentIndex = 0, setCurrentIndex, isUpdating }: FormFlowProps) {
    const {
        fields = [],
        onSubmit,
        ...restFormBuilderProps
    } = formBuilderProps || {}

    const currentField: Field = {
        ...fields[currentIndex],
        width: showAll ? fields[currentIndex]?.width || 12 : 12
    }

    function handleSubmit(values: any) {
        if (_.isFunction(setCurrentIndex)) {
            if (currentIndex < fields.length - 1) {
                setCurrentIndex(currentIndex + 1)
            } else {
                if (_.isFunction(onSubmit)) {
                    onSubmit(values)
                    setCurrentIndex(0)
                }
            }
        }
    }

    return (
        <FormBuilder
            hideHelpers
            initialValues={{}}
            {...restFormBuilderProps}
            onSubmit={handleSubmit}
            fields={showAll ? fields : [currentField]}
            submitCaption={currentIndex === fields.length - 1
                ? formBuilderProps?.submitCaption || 'Guardar'
                : 'Continuar'}
            submitButtonProps={{
                variant: isUpdating ? 'outlined' : 'contained',
                color: "primary",
                size: "small",
                endIcon: currentIndex === fields.length - 1
                    ? <></>
                    : <ArrowRightIcon style={{ width: 16 }} />,
                disabled: isUpdating,
                style: { width: 'auto' },
                ...formBuilderProps?.submitButtonProps
            }}
            validationSchema={getValidationSchema(showAll ? fields : [currentField])}
        />
    )
}